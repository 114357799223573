import React, {FC, MutableRefObject, useContext, useRef,} from 'react';
import ImageComponent from "../utilities/ImageComponent";
import IframeVideo from "../utilities/VideoUtils/IframeVideo";
import {
    getFormattedEventData,
    getTimezoneAbbr,
    getVideoElementTypeFromItemType,
} from "../../utils/CleversiteUtilities";
import Html5Video from "../utilities/VideoUtils/Html5Video";
import moment from "moment";
import {capitalize, getPlatform, getSocialIcon, stripImageTags} from "../../utils/StringUtilities";
import styles from "./styles/ListItemGroup.module.scss";
import FlatIcon from "../utilities/FlatIcon";
import FontAwesome from "../utilities/FontAwesome";
import {observer} from "mobx-react";
import {modalStore, StoreContext} from "../../stores/StoreLoader";
import classNames from 'classnames';
import {getContentItemIcon} from "../blocks/TerracedGridTheme/FilesFoldersBlock/fileUtilities";
import ClickableLink from "../utilities/ClickableLink";
import SocialMarkdown from "../utilities/SocialMarkdown";
import {sourceTypesEnum} from "../../utils/SourceUtils";
import {
    IReactiveCourseWorkContentItem,
    IReactiveEventContentItem,
    IReactiveFileContentItem,
    IReactiveFolderContentItem,
    IReactiveMediaContentItem,
    IReactiveNewsContentItem,
    isCoursework,
} from "../../stores/SchoolFeedStore";
import {getCourseWorkIcons, handleContentItemClick,} from "../blocks/SchoolBlocks/ContentItemBlock/ContentItemBlock";
import WatsonApi from "../../backends/WatsonApi";
import {SocialItemModalNavigationProps} from "../modals/SocialItemModal";
import {buildContentItemUrl, isSchoolFeedStandalone} from "../../utils/SchoolBlocksUtilities";

function ContentItemDate(props: {
    contentItem: ContentItem,
}) {
    const date = moment(props.contentItem.created_at).format('MMM D, YYYY');

    return <span className={styles.date}>
        {date}
    </span>
}

export function isEvent(item: EventContentItem | ContentItem): item is EventContentItem {
    return (item as EventContentItem).is_event === true;
}

export function isMedia(item: MediaContentItem | ContentItem): item is MediaContentItem {
    return (item as MediaContentItem).is_media === true;
}

export function isFile(item: FileContentItem | ContentItem): item is FileContentItem {
    return (item as FileContentItem).is_file === true;
}

export function isFolder(item: FileContentItem | ContentItem): item is FileContentItem {
    return (item as FolderContentItem).is_folder === true;
}

export function isNews(item: NewsContentItem | ContentItem): item is NewsContentItem {
    return (item as NewsContentItem).is_news === true;
}


export const Media = observer((props) => {
    let media: React.ReactElement | null = <></>;
    if (isMedia(props.itemObj) && props.itemObj.json_data.videos && props.itemObj.json_data.videos.videos_list) {
        const type = getVideoElementTypeFromItemType(props.itemObj.type);
        media = type === "iframe" ?
            <IframeVideo embedLink={props.itemObj.json_data.videos.videos_list[0].raw_url}/> :
            <Html5Video embedLink={props.itemObj.json_data.videos.videos_list[0].raw_url}/>;
    } else if (isEvent(props.itemObj) && props.itemObj.json_data.event) {
        const start = moment(props.itemObj.json_data.event.start);
        const end = moment(props.itemObj.json_data.event.end);

        const startMonth = start.format("MMM");
        const startDay = start.format("D");

        if (props.itemObj.json_data.event.multi_day) {
            const endMonth = end.format("MMM");
            const endDay = end.format("D");

            if (startMonth === endMonth) {
                media = <div className={props.style}>
                    <span>{startMonth}</span>
                    <span>{startDay} - {endDay}</span>
                </div>
            } else {
                media = <div className={props.style}>
                    <span>{startMonth} {startDay} -</span>
                    <span>{endMonth} {endDay}</span>
                </div>
            }
        } else {
            media = <div className={props.style}>
                <span>{startMonth}</span>
                <span>{startDay}</span>
            </div>
        }
    }
    else if (isCoursework(props.itemObj)) {
        media = getCourseWorkIcons(props.itemObj);
    }
    else if (props.itemObj.image) {
        const imageIsSourceImage = props.itemObj.image === props.itemObj.source?.account_image || props.itemObj.image === props.itemObj.source?.account_image?.replace("_normal", "");
        media = <ImageComponent data-smallwidth={imageIsSourceImage}
                                src={props.itemObj.image}
                                alt={props.itemObj.altText}
                                onClick={props.onClick}/>
    }

    return media;
})

export function getListItemIndex () {
    const focusedElement = document.activeElement as HTMLElement;
    const items = Array.from(document.getElementsByClassName(scrollItemId) as HTMLCollectionOf<HTMLDivElement>);

    return items.findIndex(el => el === focusedElement);
}

export function ItemDetails (props) {
    let itemDetails;
    let formatted;

    if (props.itemObj.is_event && (props.itemObj as IReactiveEventContentItem).json_data.event) {
        formatted = getFormattedEventData(props.itemObj, undefined, "h:mma");
    }
    if (isEvent(props.itemObj)) {
        itemDetails = <div className={props.style}>
            {formatted?.timeStr && <div>
                <FlatIcon name={'flaticon-clock'}/>&nbsp;<span>{formatted.timeStr}</span>&nbsp;<span>{formatted.timeStr !== "All Day" && getTimezoneAbbr()}</span>
            </div>}
            {props.itemObj.json_data.event.location && <div role={"button"} onClick={() => modalStore.addModal({
                type: "schoolBlocksEvent",
                event: props.itemObj,
            })}>
                <FlatIcon name={'flaticon-map'}/> {props.itemObj.json_data.event.location}
            </div>}
        </div>
    } else if (isFile(props.itemObj)) {
        const iconUrl = getContentItemIcon(props.itemObj);
        itemDetails = <div className={props.style}>
            <ClickableLink href={props.itemObj.json_data.file.view_link}>
                <ImageComponent src={iconUrl} alt={"File Icon"} />
                Click to View File
            </ClickableLink>
        </div>
    }

    return itemDetails;
}

export const JoinDiscussionButton = (props: {
    itemObj: IReactiveNewsContentItem
}) => {
    const {organizationStore} = useContext(StoreContext);
    const schoolFeedStandalone = isSchoolFeedStandalone()

    const schoolFeedLink = schoolFeedStandalone ? "" : (organizationStore.organization.schoolfeed_hostname +
        buildContentItemUrl(props.itemObj.organization_id, props.itemObj.id));


    return <div>
        <ClickableLink aria-label={"Link to content item page"}
                       onClick={schoolFeedStandalone ? undefined : (e) => e.stopPropagation()}
                       href={schoolFeedLink}
                       className={styles.joinTheDiscussion}> </ClickableLink>
    </div>
}

export const scrollItemId = "scroll-item-news";

const Item = observer((props: {
    itemObj: IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem | IReactiveFolderContentItem | IReactiveFileContentItem | IReactiveMediaContentItem,
    allowCuration: boolean,
    deleteItem: (item: IReactiveEventContentItem | IReactiveNewsContentItem) => void,
    unpublishItem: (item: IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem | IReactiveFolderContentItem | IReactiveFileContentItem | IReactiveMediaContentItem) => void,
    handleClick: () => void,
    handleKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void,
}) => {
    const {userStore, sidebarStore, organizationStore} = useContext(StoreContext);
    const itemRef: MutableRefObject<any> = useRef(null);

    const itemClassName = classNames({
        [styles.item]: true,
        [styles.itemHidden]: !props.itemObj.published,
    })

    let sourceIconLink;
    const platform = getPlatform(props.itemObj.type);
    if (props.itemObj.third_party_url && ![sourceTypesEnum.ICAL, sourceTypesEnum.RSS].includes(platform)) {
        const iconClassName = classNames({
            [styles[`sourceIcon${capitalize(platform)}`]]: true,
            [styles.sourceIcon]: true,
        })
        sourceIconLink = <ClickableLink className={iconClassName} href={props.itemObj.third_party_url} title={props.itemObj.source.account_title}>
            <FontAwesome name={getSocialIcon(platform, true)} />
        </ClickableLink>
    }
    const titleClassName = classNames({
        [styles.title]: true,
    })

    return (
        <div
            className={`${itemClassName} ${scrollItemId}`}
            ref={itemRef}
            tabIndex={0}
            onClick={e => {
              e.stopPropagation();
              props.handleClick();
            }}
            onKeyDown={e => {
                if (e.key === "Enter") {
                    e.preventDefault();
                    props.handleClick();
                }
                props.handleKeyDown && props.handleKeyDown(e);
            }}
      >
            {userStore.isEditor && (
                <div className={styles.curationContainer}>
                    <button
                        aria-label={'Edit Content Item'}
                        onClick={e => {
                            e.stopPropagation();
                            sidebarStore.setSidebar({
                              view: "SchoolFeedPost",
                              contentItem: props.itemObj,
                              handleDelete: contentItem => {
                                props.deleteItem(contentItem);
                              },
                            });
                        }}
            >
                        <FontAwesome ariaHidden prefix={"fas"} name={"fa-pencil-alt"} />
                    </button>
                </div>
        )}

            <div className={styles.header}>
                {props.itemObj.title && (
                    <div className={titleClassName}>
                        {isNews(props.itemObj) && <div className={styles.dateContainer}>
                            <ContentItemDate contentItem={props.itemObj} />
                            <span className={styles.orgTitle}>{" · " + props.itemObj.organization.title}</span>
                        </div>}
                        <SocialMarkdown platform={platform} text={props.itemObj.title}/>
                    </div>
          ) }

                {isCoursework(props.itemObj) &&
            props.itemObj.json_data?.coursework?.dueDate && (
                <div className={styles.date} style={{ fontWeight: "bold" }}>
                    Due Date:{" "}
                    {moment(props.itemObj.json_data?.coursework?.dueDate).format(
                  "MMM D, YYYY"
                )}
                </div>
            )}
                {isEvent(props.itemObj) && (
                    <ItemDetails
              itemObj={props.itemObj}
              style={styles.dateLocationContainer}
            />
          )}
                    {props.itemObj.description && (
                        <div
              className={styles.description}
            >
                            {sourceIconLink}
                            <SocialMarkdown
                text={props.itemObj.description}
                platform={platform}
              />
                        </div>
          )}
                {props.itemObj.meritchat_enabled &&
                    organizationStore.schoolFeedEnabledForOrganization &&
                    isNews(props.itemObj) &&
                    <JoinDiscussionButton itemObj={props.itemObj}/>}
                {isFile(props.itemObj) && (
                    <ItemDetails
              itemObj={props.itemObj}
              style={styles.dateLocationContainer}
            />
          )}

            </div>
        </div>
    );
});

const ListItemGroup: FC<{
    items: Array<IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem | IReactiveFolderContentItem | IReactiveFileContentItem | IReactiveMediaContentItem>,
    allowCuration: boolean,
    showMedia: boolean,
    firstItemIndex: number,
    handleDelete?: (c: ContentItem) => void,
    handleKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void,
} & Omit<SocialItemModalNavigationProps, "initialItemIndex">> = observer(props => {
    const firstItem = props.items[0];

    async function deleteItem(item: IReactiveNewsContentItem | IReactiveEventContentItem) {
        const client = await WatsonApi();
        await client.apis.organizations.organizations_content_items_delete({
            id: item.id,
            organization_pk: item.organization_id,
        });
        if (props.handleDelete) {
            props.handleDelete(item);
        }
    }

    async function unpublishItem(item: IReactiveNewsContentItem | IReactiveEventContentItem | IReactiveCourseWorkContentItem) {
        const client = await WatsonApi();
        const result = await client.apis.organizations.organizations_content_items_partial_update({
            id: item.id,
            organization_pk: item.organization_id,
            data: {"user_published": !item.published},
        });
        item.update(result.obj);
    }

    const groupContainerClassName = classNames({
        [styles.groupContainer]: true,
        [styles.groupContainerCenteredImage]: firstItem.image && props.items.length > 1,
    })

    return <div className={groupContainerClassName}>
        {props.showMedia && <Media itemObj={firstItem} style={styles.dateMedia} onClick={(e) => {
            e.stopPropagation();
            handleContentItemClick(firstItem, {
                getItemAtIndex: props.getItemAtIndex,
                totalItemCount: props.totalItemCount,
                initialItemIndex: props.firstItemIndex,
            }, deleteItem) }
        }/>}
        <div className={styles.contentContainer}>
            {props.items.map((item, idx) => {
                return <Item
                    allowCuration={props.allowCuration}
                    key={item.id}
                    itemObj={item}
                    deleteItem={deleteItem}
                    unpublishItem={unpublishItem}
                    handleClick={() => handleContentItemClick(item, {
                        getItemAtIndex: props.getItemAtIndex,
                        totalItemCount: props.totalItemCount,
                        initialItemIndex: props.firstItemIndex + idx,
                    }, deleteItem)}
                    handleKeyDown={props.handleKeyDown}
                /> })}
        </div>
    </div>
})

export default ListItemGroup
